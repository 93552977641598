<template>
  <div>
    <div
      class="role-list"
      :class="[`role-list--${roleData.length}`]">
      <div
        v-for="(item, idx) in roleData"
        :key="item.role"
        class="role-list__item"
        :class="[`role-list__item--${item.role}`]">
        <RoleCard
          class="role-list__card"
          :role="item.role"
          :show-btn="item.status === STATUSES.ALLOW">
          <template #label>{{ item.title }}</template>
          <template
            v-if="idx === 0 && roleData.length === 3"
            #label_info
            >Шаг 1&nbsp;из&nbsp;3&nbsp;&mdash; выбор роли</template
          >
          <template #label_img>
            <img :src="images[item.role]" />
          </template>
          <template #body>
            <div v-html="item.content"></div>
            <div
              v-if="item.status !== STATUSES.ALLOW"
              class="not-allowed">
              <div v-if="item.status === STATUSES.ENDED">
                Прием заявок на текущий сезон завершен. Приглашаем пройти
                <a
                  :href="preRegUrl"
                  class="link"
                  >предварительную регистрацию</a
                >
                на&nbsp;новый сезон НТО, и&nbsp;мы&nbsp;уведомим вас
                о&nbsp;старте олимпиады!
              </div>
              <div v-else-if="item.status === STATUSES.NOT_STARTED">
                Прием заявок на текущий сезон еще не начался. Приглашаем пройти
                <a
                  :href="preRegUrl"
                  class="link"
                  >предварительную регистрацию</a
                >
                на&nbsp;новый сезон НТО, и&nbsp;мы&nbsp;уведомим вас
                о&nbsp;старте олимпиады!
              </div>
            </div>
          </template>
        </RoleCard>
      </div>
    </div>
  </div>
</template>

<script>
import RoleCard from "@/components/registration/RoleCard.vue";
import ROLE_DATA from "@/data/role_data.json";
import { mapGetters } from "vuex";
import {
  LOW_SCHOOL_ALIAS,
  PARTICIPANT_ROLE,
  REGISTRATION_STAGE,
  SCHOOL_ALIAS,
  STUDENT_ALIAS,
} from "@/constants";

const STATUSES = {
  DISALLOW: 0,
  ALLOW: 1,
  ENDED: 2,
  NOT_STARTED: 3,
};

const getStatus = (startAt, endAt) => {
  if (!startAt || !endAt) return STATUSES.NOT_STARTED;
  const now = Date.now();
  const start = new Date(startAt).getTime();
  const end = new Date(endAt).getTime();
  if (now > end) return STATUSES.ENDED;
  if (now < start) return STATUSES.NOT_STARTED;
  return STATUSES.ALLOW;
};

const images = {
  school: require(`@/assets/images/reg_school.svg`),
  mentor: require(`@/assets/images/reg_mentor.svg`),
  student: require(`@/assets/images/reg_student.svg`),
};
export default {
  name: "RegistrationRoleSelect",
  components: {
    RoleCard,
  },
  metaInfo() {
    return {
      title: "Регистрация на олимпиаду. Выбор роли",
    };
  },
  data() {
    return {
      images,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      age: "user/age",
    }),

    regStatuses() {
      const list = this.$store.state.schedule;
      const result = {
        mentor: STATUSES.ALLOW,
        student: STATUSES.DISALLOW,
        school: STATUSES.DISALLOW,
      };
      if (!list) return result;
      const schedule = list.filter((n) => n.stage === REGISTRATION_STAGE) || [];
      if (!schedule.length) return result;

      const studSch = schedule.find((n) => n.track?.alias === STUDENT_ALIAS);
      const schoolSch = schedule.filter((n) =>
        [SCHOOL_ALIAS, LOW_SCHOOL_ALIAS].includes(n.track?.alias)
      );
      result.student = getStatus(studSch?.start_at, studSch?.end_at);
      const isAllowSchool = schoolSch.some(
        (n) => getStatus(n.start_at, n.end_at) === STATUSES.ALLOW
      );
      result.school = isAllowSchool
        ? STATUSES.ALLOW
        : getStatus(schoolSch[0]?.start_at, schoolSch[0]?.end_at);
      return result;
    },
    /**
     * Возвращает отсортированный список с ролями,
     * в зависимости от возраста пользователя
     */
    roleData() {
      const { age, user, regStatuses } = this;
      const [school, student, mentor] = ROLE_DATA;
      school.status = regStatuses.school;
      student.status = regStatuses.student;
      mentor.status = regStatuses.mentor;

      if (user?.role === PARTICIPANT_ROLE) {
        return age > 17 ? [student, school] : [school, student];
      }
      // Вероятнее всего ментор
      if (age > 25) {
        return [mentor, student, school];
      }
      // Вероятнее всего студент
      if (age > 17) {
        return [student, mentor, school];
      }
      return [school, student, mentor];
    },

    preRegUrl() {
      return `${process.env.VUE_APP_TALENT_BASE_URL}/registration?event=${process.env.VUE_APP_PREREGISTRATION_EVENT_ID}`;
    },
  },
  mounted() {
    this.getSchedule();
  },
  created() {
    this.STATUSES = STATUSES;
  },
  methods: {
    getSchedule() {
      this.$store.dispatch("getSchedule").catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.not-allowed {
  margin-top: 30px;
}
.role-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;

  &__card {
    min-height: 100%;
  }

  &__item {
    padding: 0 12px;
    margin-bottom: 33px;
    flex: 0 0 50%;
    max-width: 50%;
  }

  &--3 &__item:nth-child(1) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media screen and (max-width: @screen-md) {
    &__item {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
</style>
